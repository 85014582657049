import React, {useState} from 'react'
import videoImg from "../../../../../images/icons/iconsForCampaignReport/video 1.svg";

export const ReportCampaignMobileTable = ({data, totalFollowers, totalPrice, totalImpressions, totalLikes}) => {
    const [seeAllDescriptions, setSeeAllDescriptions] = useState({});
    const [seeAllSpecialRequests, setSeeAllSpecialRequests] = useState({});

    const toggleSeeAllPostDescription = (username) => {
        setSeeAllDescriptions(prevState => ({
            ...prevState,
            [username]: !prevState[username]
        }));
    };

    const toggleSeeAllSpecialRequests = (username) => {
        setSeeAllSpecialRequests(prevState => ({
            ...prevState,
            [username]: !prevState[username]
        }));
    }

    return (
        <div className="report-mobile-table">
            {data.selectInfluencers.map((addedAccount, index) => {
                const isExpandedPostDescription = seeAllDescriptions[addedAccount.socialMediaUsername] || false;
                const isExpandedSpecialRequests = seeAllSpecialRequests[addedAccount.socialMediaUsername] || false;

                return (
                    <div className="account-item">
                        <div className="header" style={{borderRadius: index === 0 ? "30px 30px 0 0" : "0"}}>
                            {addedAccount.socialMediaUsername}
                        </div>
                        <div className="body">
                            <div className="account-info"
                                 style={{borderRadius: index === data.selectInfluencers.length - 1 ? "0 0 0 30px" : 0}}>
                                <div id="total-followers" style={{marginTop: 13}}>
                                    <p>Total Followers</p>
                                    <span>{totalFollowers}</span>
                                </div>
                                {index === data.selectInfluencers.length - 1 && (
                                    <div className="footer-left-side">
                                        <p>PRICE: {totalPrice}€</p>
                                    </div>
                                )}
                            </div>

                            <div className="campaign-info"
                                 style={{borderRadius: index === data.selectInfluencers.length - 1 ? "0 0 30px 0" : 0}}>
                                <div id="date-request" style={{marginTop: 13}}>
                                    <p>Date Request</p>
                                    <span>{addedAccount.dateRequest}</span>
                                </div>
                                <div id="videoLink">
                                    <p>Video</p>
                                    {addedAccount.selectedCampaignContentItem && (
                                        <div id="img"
                                             onClick={() => window.open(addedAccount.selectedCampaignContentItemObj.videoLink)}>
                                            <img src={videoImg} alt="video"/>
                                            <span>#{data.campaignContent.findIndex((content) => addedAccount.selectedCampaignContentItem === content._id) + 1}</span>
                                        </div>
                                    )}
                                </div>
                                <div id="post-description">
                                    <p>Post Description</p>
                                    <div style={{maxHeight: isExpandedPostDescription ? 1000 : 58, overflow: "hidden"}}>
                                        <span>{addedAccount.selectedCampaignContentItem && addedAccount.selectedCampaignContentItemObj && addedAccount.selectedCampaignContentItemObj.postDescription}</span>
                                    </div>
                                    <button
                                        onClick={() => toggleSeeAllPostDescription(addedAccount.socialMediaUsername)}>
                                        {isExpandedPostDescription ? "SEE LESS" : "SEE ALL"}
                                    </button>
                                </div>
                                <div id="story-tag">
                                    <p>Story Tag</p>
                                    <span>{addedAccount.selectedCampaignContentItem && addedAccount.selectedCampaignContentItemObj && addedAccount.selectedCampaignContentItemObj.storyTag}</span>
                                </div>
                                <div id="story-link">
                                    <p>Story Link</p>
                                    <div>
                            <span
                                onClick={() => window.open(addedAccount.selectedCampaignContentItemObj, "_blank")}>
                                {addedAccount.selectedCampaignContentItem && addedAccount.selectedCampaignContentItemObj && addedAccount.selectedCampaignContentItemObj.swipeUpLink}
                            </span>
                                    </div>
                                </div>
                                <div id="post-description" style={{marginBottom: 9}}>
                                    <p>Special Requests</p>
                                    <div style={{maxHeight: isExpandedSpecialRequests ? 1000 : 58, overflow: "hidden"}}>
                                        <span>{addedAccount.selectedCampaignContentItem && addedAccount.selectedCampaignContentItemObj && addedAccount.selectedCampaignContentItemObj.specialWishes}</span>
                                    </div>
                                    <button onClick={() => toggleSeeAllSpecialRequests(addedAccount.socialMediaUsername)}>
                                        {isExpandedSpecialRequests ? "SEE LESS" : "SEE ALL"}
                                    </button>
                                </div>
                                {index === data.selectInfluencers.length - 1 && (
                                    <div className="footer-right-side">
                                        <p>FOLLOWERS: {totalFollowers}</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    )
}