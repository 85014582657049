import React, {useEffect, useRef, useState} from "react";
import backBtn from "../../../../images/icons/arrow.svg";
import TitleSection from "../../../TitleSection";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import Loading from "../../../form/PageLoading/pageLoading";
import {extractNumber} from "../../../../utils/validations";
import videoImg from "../../../../images/icons/iconsForCampaignReport/video 1.svg";
import linkImg from "../../../../images/icons/iconsForCampaignReport/link 1.svg";
import editImg from "../../../../images/icons/edit 1.svg";
import deleteImg from "../../../../images/icons/adminPanel/campaignManagement/close 8.svg";
import addImg from "../../../../images/icons/adminPanel/campaignManagement/plus 2 2.svg";
import emailImg from "../../../../images/icons/mail (1) 1.svg";
import instagramIcon from "../../../../images/icons/iconsForCampaignReport/instagram 1.svg";
import imageImg from "../../../../images/icons/iconsForCampaignReport/image- 1.svg";
import addSecondImg from "../../../../images/icons/plus 2.svg";
import ModalWindow from "../../../ModalWindow";
import StandardButton from "../../../form/StandardButton";
import TextInput from "../../../form/TextInput";
import SubmitButton from "./form/Influencers/SubmitFooter/SubmitButton";
import checkImg from "../../../../images/vector.png";
import hideImg from "../../../../images/icons/hidden 63.svg";
import {encryptAndEncode} from "../../../../utils/crypt";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {getSocialMediaIconForPickAndChoose} from "../../../../utils/typeOfSocialAccounts";
import {
    SelectSocialMediaHorizontalList
} from "../../../form/SelectSocialMediaHorizontal/SelectSocialMediaHorizontalList";
import {setSelectSocialMedia} from "../../../../redux/slice/proposal-system";

const AdminCampaignManagement = () => {
    const [data, setData] = useState();
    const [cpmObj, setCpmObj] = useState({
        cpm: 0,
        avgCpm: '',
        result: '',
    });
    const [isVideoLinkEdit, setIsVideoLinkEdit] = useState(false);
    const [isNewInfluencerVideoLinkEdit, setIsNewInfluencerVideoLinkEdit] = useState(false);
    const [isPostLinkEdit, setIsPostLinkEdit] = useState(false);
    const [isScreenshotEdit, setIsScreenshotEdit] = useState(false);
    const [isAddInfluencerModaolOpen, setIsAddInfluencerModaolOpen] = useState(false);
    const containerRef = useRef(null);
    const saveChangesRef = useRef(null);
    const saveChangesCampaignRef = useRef(null);
    const containerCampaignRef = useRef(null);
    const [fieldsForChangeVideo, setFieldsForChangeVideo] = useState({
        _id: '',
        videoLink: '',
        postDescription: '',
        storyTag: '',
        swipeUpLink: '',
        influencerId: '',
        selectedInstagramUsername: '',
        selectedInfluencersAccountId: '',
        currentCampaignContentItemId: '',
        newCampaignContentItemId: '',
        specialWishes: '',
    });
    const [fieldsForChangeCampaign, setFieldsForChangeCampaign] = useState({
        _id: '',
        campaignName: '',
        amount: '',
    });
    const [fieldsForChangeInfluencer, setFieldsForChangeInfluencer] = useState({
        _id: '',
        selectedInfluencersAccountId: '',
        instagramUsername: '',
        datePost: '',
        impressions: '',
        like: '',
        postLink: '',
        screenshot: '',
    });
    const [newInfluenceInput, setNewInfluenceInput] = useState('');
    const [newInfluencersList, setNewInfluencersList] = useState([]);
    const [newInfluencerVideoLink, setNewInfluencerVideoLink] = useState('');
    const [isErrorAfterAddingInfluencer, setIsErrorAfterAddingInfluencer] = useState(false);
    const [selectedNewInfluencer, setSelectedNewInfluencer] = useState({
        _id: '',
        influencerId: '',
        instagramUsername: '',
        confirmation: 'wait',
        selectedCampaignContentItem: '',
        dateRequest: '',
        closePromo: 'wait',
        postLink: '',
        screenshot: '',
        impressions: '',
        like: '',
        followersCount: '',
        socialMedia: ''
    });
    const [isDeleteInfluencerModalOpen, setIsDeleteInfluencerModalOpen] = useState(false);
    const [isCpmAndResultHidden, setIsCpmAndResultHidden] = useState(false);
    const [isPriceHidden, setIsPriceHidden] = useState(false);
    const [isClosePromoModalOpen, setIsClosePromoModalOpen] = useState(false);
    const [inputForNewVideoLink, setInputForNewVideoLink] = useState('');
    const [selectedSocialMedia, setSelectedSocialMedia] = useState(data?.socialMedia || 'instagram')

    const navigate = useNavigate();
    const params = useParams();
    const getData = async () => {
        try {
            if (!params.campaignId) return navigate('/admin/campaigns');

            const encryptedCampaignId = encryptAndEncode(params.campaignId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);

            const result = await axios.get(
                `${process.env.REACT_APP_SERVER}/admin/campaigns/getOne/${encryptedCampaignId}`,
            );

            if (result.status === 200) {
                const mergedInfluencers = mergeInfluencersWithVideos(result.data.campaign.selectInfluencers, result.data.campaign.videos);
                setData({
                    ...result.data.campaign,
                    selectInfluencers: mergedInfluencers
                });
                setIsCpmAndResultHidden(result.data.campaign.isCpmAndResultHidden);
                setIsPriceHidden(result.data.campaign.isPriceHidden);
                return;
            }

            NotificationManager.error('Campaign not found', 'Error', 3000);
        } catch (err) {
            NotificationManager.error('Campaign not found', 'Error', 3000);
            console.log(err);
        }
    }

    const mergeInfluencersWithVideos = (influencers, videos) => {
        return influencers.map(influencer => {
            if (influencer.selectedVideo) {
                const selectedVideo = videos.find(video => video.videoLink === influencer.selectedVideo);
                if (selectedVideo) {
                    return {
                        ...influencer,
                        videoDetails: selectedVideo
                    };
                }
                return influencer;
            } else if (influencer.selectedCampaignContentItem) {
                const selectedVideo = videos.find(video => video._id === influencer.selectedCampaignContentItem);
                if (selectedVideo) {
                    return {
                        ...influencer,
                        videoDetails: selectedVideo
                    };
                }
                return influencer;
            }
            return influencer;
        });
    }

    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data && !data?.isCpmAndResultHidden && JSON.stringify(cpmObj) === JSON.stringify({
            cpm: 0,
            avgCpm: '',
            result: ''
        })) {
            editCPMO();
        }
    }, [data]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target) &&
                saveChangesRef.current &&
                !saveChangesRef.current.contains(event.target)
            ) {
                setFieldsForChangeVideo({
                    _id: '',
                    videoLink: '',
                    postDescription: '',
                    storyTag: '',
                    swipeUpLink: '',
                    influencerId: '',
                    selectedInstagramUsername: '',
                    selectedInfluencersAccountId: '',
                    currentCampaignContentItemId: '',
                    newCampaignContentItemId: '',
                    specialWishes: '',
                });

                setFieldsForChangeInfluencer({
                    _id: '',
                    selectedInfluencersAccountId: '',
                    instagramUsername: '',
                    datePost: '',
                    impressions: '',
                    like: '',
                    postLink: '',
                    screenshot: '',
                });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerCampaignRef.current &&
                !containerCampaignRef.current.contains(event.target) &&
                saveChangesCampaignRef.current &&
                !saveChangesCampaignRef.current.contains(event.target)
            ) {
                setFieldsForChangeCampaign({
                    _id: '',
                    campaignName: '',
                    amount: '',
                });
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const editCPMO = () => {
        if (data?.selectInfluencers?.find(influencer => influencer.impressions > 0)) {
            const price = data.amount ? data.amount : data.selectPrice.price;
            const cpm = price / totalImpressions() * 1000;
            let avgCpm;

            if (cpm < 3) {
                avgCpm = '0 to 3€';
            } else if (cpm < 5) {
                avgCpm = '3€ to 5€';
            } else if (cpm < 9) {
                avgCpm = '5€ to 9€';
            } else if (cpm < 12) {
                avgCpm = '9€ to 12€';
            } else {
                avgCpm = '>12€';
            }

            let result;
            if (avgCpm === '0 to 3€') {
                result = 'Excellent';
            } else if (avgCpm === '3€ to 5€') {
                result = 'Highly Above Average';
            } else if (avgCpm === '5€ to 9€') {
                result = 'Above Average';
            } else if (avgCpm === '9€ to 12€') {
                result = 'Average';
            } else if (avgCpm === '> 12€') {
                result = 'Below Average';
            } else {
                result = 'Poor';
            }

            setCpmObj({
                cpm,
                avgCpm,
                result,
            });
        }
    }

    const totalImpressions = () => {
        if (!data?.selectInfluencers) return 0;
        const total = data?.selectInfluencers.reduce((prev, current) => {
            return prev + extractNumber(current.impressions);
        }, 0);

        return total;
    };

    const totalLikes = () => {
        if (!data?.selectInfluencers) return 0;
        const total = data.selectInfluencers.reduce((prev, current) => {
            return prev + extractNumber(current.like);
        }, 0);

        return total;
    };

    const getStatusForInfluencer = (influencer) => {
        if (influencer.confirmation === 'refusing') return 'Denied';

        if (influencer.confirmation === 'accept') {
            if (influencer.closePromo === 'close') {
                return 'Closed';
            } else {
                if (influencer.screenshot !== '') {
                    return 'Insights Sent';
                }
                return 'Pending';
            }
        }

        return 'N/A';
    }

    const selectInfluencer = (influencer) => {
        if (fieldsForChangeVideo.selectedInfluencersAccountId !== influencer._id && fieldsForChangeInfluencer.selectedInfluencersAccountId !== influencer._id) {
            setFieldsForChangeVideo({
                _id: data._id,
                videoLink: influencer.videoDetails.videoLink,
                postDescription: influencer.videoDetails.postDescription,
                storyTag: influencer.videoDetails?.storyTag,
                swipeUpLink: influencer.videoDetails?.swipeUpLink,
                influencerId: influencer.influencerId,
                selectedInstagramUsername: influencer.instagramUsername,
                selectedInfluencersAccountId: influencer._id,
                currentCampaignContentItemId: influencer.videoDetails._id,
                newCampaignContentItemId: '',
                specialWishes: influencer.videoDetails?.specialWishes,
            });

            setFieldsForChangeInfluencer({
                _id: data._id,
                selectedInfluencersAccountId: influencer._id,
                instagramUsername: influencer.instagramUsername,
                datePost: influencer.datePost,
                impressions: influencer.impressions,
                like: influencer.like,
                postLink: influencer.postLink,
                screenshot: influencer.screenshot,
            });
        }
    };

    const updateCampaignVideoFieldsInput = (e) => {
        setFieldsForChangeVideo({
            ...fieldsForChangeVideo,
            [e.target.name]: e.target.value,
        });
    };

    const updateCampaignFieldsInput = (e) => {
        setFieldsForChangeCampaign({
            ...fieldsForChangeCampaign,
            [e.target.name]: e.target.value,
        });
    };

    const updateInfluencerFieldsInput = (e) => {
        setFieldsForChangeInfluencer({
            ...fieldsForChangeInfluencer,
            [e.target.name]: e.target.value,
        });
    };

    const updateCampaignVideoOnServer = async () => {
        try {
            // const isNewCampaignContentItem = fieldsForChangeVideo.newCampaignContentItemId !== "" && (data?.videos.findIndex(video => video._id === fieldsForChangeVideo.newCampaignContentItemId) === -1);

            await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/campaignContentItem`,
                {
                    _id: fieldsForChangeVideo._id,
                    selectInfluencersAccountId: fieldsForChangeInfluencer.selectedInfluencersAccountId,
                    campaignContentItemId: fieldsForChangeVideo.newCampaignContentItemId !== "" ? fieldsForChangeVideo.newCampaignContentItemId : fieldsForChangeVideo.currentCampaignContentItemId,
                    videoLink: fieldsForChangeVideo.videoLink,
                    postDescription: fieldsForChangeVideo.postDescription,
                    storyTag: fieldsForChangeVideo.storyTag,
                    swipeUpLink: fieldsForChangeVideo.swipeUpLink,
                    specialWishes: fieldsForChangeVideo.specialWishes,
                    selectedSocialMediaAccountUsername: fieldsForChangeVideo.selectedInstagramUsername,
                }
            );

            const res = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/influencers-list`,
                {
                    campaignId: fieldsForChangeInfluencer._id,
                    selectInfluencersAccountId: fieldsForChangeInfluencer.selectedInfluencersAccountId,
                    socialMediaUsername: fieldsForChangeInfluencer.instagramUsername,
                    impressions: fieldsForChangeInfluencer.impressions,
                    like: fieldsForChangeInfluencer.like,
                    postLink: fieldsForChangeInfluencer.postLink,
                    screenshot: fieldsForChangeInfluencer.screenshot,
                }
            );

            if (res.data.status === 200) {
                window.location.reload()
                return;
            }

            throw new Error('Error while updating influencer details');
        } catch (err) {
            NotificationManager.error('Error while updating influencer details', 'Error', 3000);
            console.log(err);
        }
    }

    const updateCampaignOnServer = async () => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update`,
                {
                    _id: fieldsForChangeCampaign._id,
                    campaignName: fieldsForChangeCampaign.campaignName,
                    campaignPrice: fieldsForChangeCampaign.amount,
                }
            );

            if (result.data.code === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while updating campaign details');
        } catch (error) {
            NotificationManager.error('Error while updating campaign details', 'Error', 3000);
            console.log(error);
        }
    };

    const getNumOfInfluencersVideo = (influencer) => {
        if (influencer.videoDetails) {
            return data.videos.findIndex(video => video._id === influencer.videoDetails._id) + 1;
        }
    }

    const removeInfluencer = async (selectInfluencersAccountId) => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/remove-influencer/${encryptAndEncode(data._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}/${encryptAndEncode(selectInfluencersAccountId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`,
            );

            if (result.data.code === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while removing influencer');
        } catch (error) {
            NotificationManager.error('Error while removing influencer', 'Error', 3000);
            console.log(error);
        }
    }

    const addNewInfluencerToTempList = async () => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/add-influencer-to-temp-list/${data?._id}/${newInfluenceInput}/${selectedSocialMedia}`
            );

            if (result.data.code === 200) {
                !data?.selectInfluencers.some(acc => acc.socialMediaUsername.toLowerCase() === newInfluenceInput.toLowerCase() && acc.socialMedia === selectedSocialMedia) ?
                    NotificationManager.success(`Influencer ${newInfluenceInput} added successfully`, 'Success', 3000) :
                    NotificationManager.warning(`Influencer ${newInfluenceInput} already was in the campaign`, 'Warning', 5000);

                setNewInfluencersList((prev) => [...prev, result.data.account]);
                setNewInfluenceInput('');
                setIsAddInfluencerModaolOpen(false);
                setIsErrorAfterAddingInfluencer(false);
                return;
            }
            if (result.data.code === 404 || result.data.code === 500) {
                setIsErrorAfterAddingInfluencer(true);
                return;
            }

            throw new Error(result.data.message);
        } catch (error) {
            NotificationManager.error(error.message || 'error', 'Error', 3000);
            console.error("Error while adding influencer:", error);

            if (error.response && error.response.status === 404) {
                setIsErrorAfterAddingInfluencer(true);
            } else {
                setIsErrorAfterAddingInfluencer(true);
            }
        }
    };

    useEffect(() => {
        if (selectedNewInfluencer.selectedCampaignContentItem !== '') {
            const influencerIndex = newInfluencersList.findIndex(influencer => influencer._id === selectedNewInfluencer._id);
            if (influencerIndex !== -1) {
                newInfluencersList[influencerIndex] = selectedNewInfluencer;
                setNewInfluencersList([...newInfluencersList]);
            }

            const mergedList = mergeInfluencersWithVideos(newInfluencersList, data.videos);
            setNewInfluencersList(mergedList);
        }
    }, [selectedNewInfluencer]);

    const selectNewInfluencer = (influencer) => {
        if (selectedNewInfluencer.instagramUsername !== influencer.instagramUsername) {
            setSelectedNewInfluencer({
                _id: influencer._id,
                influencerId: influencer.influencerId,
                instagramUsername: influencer.instagramUsername,
                confirmation: influencer.confirmation,
                selectedCampaignContentItem: influencer.selectedCampaignContentItem,
                dateRequest: influencer.dateRequest,
                closePromo: influencer.closePromo,
                postLink: influencer.postLink,
                screenshot: influencer.screenshot,
                impressions: influencer.impressions,
                like: influencer.like,
                followersCount: influencer.followersCount,
                socialMedia: influencer.socialMedia,
            });
        }
    }

    const updateNewInfluencerInput = (e) => {
        setSelectedNewInfluencer({
            ...selectedNewInfluencer,
            [e.target.name]: e.target.value,
        });
    }

    const removeNewInfluencerFromList = (accountId) => {
        const newInfluencers = newInfluencersList.filter(influencer => influencer._id !== accountId);
        setNewInfluencersList(newInfluencers);
    }

    const addInfleuncerToCampaign = async (accountId) => {
        try {
            const instaObj = {...selectedNewInfluencer}

            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/add-influencer-to-campaign`,
                {
                    _id: data._id,
                    influencerId: instaObj.influencerId,
                    socialMediaUsername: instaObj.instagramUsername,
                    confirmation: instaObj.confirmation,
                    selectedCampaignContentItem: instaObj.selectedCampaignContentItem,
                    newVideoLink: inputForNewVideoLink,
                    dateRequest: instaObj.dateRequest,
                    closePromo: instaObj.closePromo,
                    postLink: instaObj.postLink,
                    screenshot: instaObj.screenshot,
                    impressions: instaObj.impressions,
                    socialMedia: instaObj.socialMedia,
                    like: instaObj.like,
                }
            );

            if (result.data.code === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while adding influencer to campaign');
        } catch (error) {
            NotificationManager.error('Error while adding influencer to campaign', 'Error', 3000);
            console.log(error);
        }
    }

    const sendMailToInfluencer = async (instagramUsername, influencerId, videoLink, postDescription, dateRequest, storyTag, storyLink) => {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_SERVER}/admin/promos/send-mail-influencer`,
                {
                    influencerId: influencerId,
                    socialMediaUsername: instagramUsername,
                    videoLink: videoLink,
                    postDescription: postDescription,
                    dateRequest: dateRequest,
                    storyTag: storyTag,
                    storyLink: storyLink,
                }
            );

            if (result.data.code === 201) {
                NotificationManager.success('Mail sent successfully', 'Success', 3000);
                return;
            }

            throw new Error('Error while sending mail to influencer');
        } catch (error) {
            NotificationManager.error('Error while sending mail to influencer', 'Error', 3000);
            console.log(error);
        }
    }

    const closeCampaignForInfluencer = async (accountId, campaignId) => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/close-for-influencer/${encryptAndEncode(campaignId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}/${encryptAndEncode(accountId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`,
            );

            if (result.data.code === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while closing campaign for influencer');
        } catch (error) {
            NotificationManager.error('Error while closing campaign for influencer', 'Error', 3000);
            console.log(error);
        }
    }

    const hideCpmAndResult = async () => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/hideCpmAndResultForCampaign/${encryptAndEncode(data._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`,
            );

            if (result.status === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while hiding CPM and result');
        } catch (error) {
            NotificationManager.error('Error while hiding CPM and result', 'Error', 3000);
            console.log(error);
        }
    };

    const hidePrice = async () => {
        try {
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/admin/promos/update/hide-price-for-campaign/${encryptAndEncode(data._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`,
            );

            if (result.status === 200) {
                window.location.reload();
                return;
            }

            throw new Error('Error while hiding CPM and result');
        } catch (error) {
            NotificationManager.error('Error while hiding CPM and result', 'Error', 3000);
            console.log(error);
        }
    };

    const closeCampaign = async () => {
        try {
            const campaignId = encryptAndEncode(params.campaignId, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY);
            const result = await axios.post(`${process.env.REACT_APP_SERVER}/admin/promos/close-campaign/${campaignId}`);

            if (result.data.status === 201) {
                navigate('/admin/campaigns');
                return;
            }

            setIsClosePromoModalOpen(!isClosePromoModalOpen);
            throw new Error('Error while closing campaign');
        } catch (error) {
            NotificationManager.error('Error while closing campaign', 'Error', 3000);
            console.log(error);
        }
    }

    const returnCopyLabel = () => {
        if (data.isCopy) {
            return (<p style={{
                color: 'red', fontFamily: "Geometria",
                fontSize: '30px',
                fontWeight: 800,
                textAlign: "center",
                width: '100%',
                marginTop: 35,
            }}>CLOSED</p>);
        }
    };

    if (!data) return (<div>
        <Loading/>
        <NotificationContainer/>
    </div>);
    return (
        <section className="admin">
            <div>
                <div>
                    <div className="admin-title-section">
                        <button onClick={() => navigate('/admin/campaigns')}>
                            <img src={backBtn} style={{transform: "rotate(180deg)"}}/>
                        </button>
                        <TitleSection title="Campaign" span="Management"/>
                    </div>
                    {data && (
                        <div ref={containerCampaignRef} onClick={() => {
                            if (fieldsForChangeCampaign.campaignName === '') {
                                setFieldsForChangeCampaign({
                                    _id: data._id,
                                    campaignName: data.campaignName,
                                    amount: data.amount,
                                });
                            }
                        }}>
                            <input
                                style={{
                                    fontFamily: "Geometria",
                                    fontSize: '24px',
                                    fontWeight: 700,
                                    textAlign: "center",
                                    width: '100%',
                                    marginTop: 35,
                                    height: 30,
                                }}
                                value={fieldsForChangeCampaign.campaignName ? fieldsForChangeCampaign.campaignName : data?.campaignName}
                                name='campaignName'
                                onChange={updateCampaignFieldsInput}
                            />

                            {returnCopyLabel()}

                            <div className="report-details">
                                <div className="report-details-first">
                                    <p>Date
                                        Submitted: <span>{new Date(data?.createdAt).toLocaleDateString('en-GB')}</span>
                                    </p>
                                    <p style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{marginRight: '10px', fontWeight: 400}}>Price:</span>
                                        {!isPriceHidden ?
                                            <input
                                                style={{
                                                    fontFamily: "Geometria",
                                                    fontSize: '24px',
                                                    fontWeight: 600,
                                                    width: '75px',
                                                    marginTop: 2,
                                                    marginLeft: '-2px',
                                                    height: 20
                                                }}
                                                value={fieldsForChangeCampaign.amount ? fieldsForChangeCampaign.amount : data?.amount}
                                                name='amount'
                                                onChange={updateCampaignFieldsInput}
                                            /> : <div style={{width: 20}}></div>}
                                        <button style={{
                                            cursor: 'pointer',
                                            marginTop: 2,
                                            marginLeft: fieldsForChangeCampaign.amount
                                                ? (String(fieldsForChangeCampaign.amount).length > 4 ? 5 : '-8px')
                                                : (String(data?.amount).length > 4 ? 5 : '-8px'),
                                        }}
                                                onClick={() => hidePrice()}>
                                            <img src={hideImg} alt="hide"/>
                                        </button>
                                    </p>

                                    <p>Posts & Stories: <span>{data?.selectInfluencers.length}</span></p>
                                </div>
                                <div className="report-details-second">
                                    <p>Combined Followers: <span>{data?.totalFollowers}</span></p>
                                    <p>Impressions: <span>{totalImpressions()}</span></p>
                                    <p>Likes: <span>{totalLikes()}</span></p>
                                </div>
                                <div className="report-details-third">
                                    <p>CPM: {!isCpmAndResultHidden && <span>{cpmObj.cpm.toFixed(2)}€</span>}
                                        <button style={{marginLeft: 10, cursor: 'pointer', padding: 5}}
                                                onClick={() => hideCpmAndResult()}>
                                            <img src={hideImg} alt="hide"/>
                                        </button>
                                    </p>
                                    <p>Average Instagram CPM: <span>5€ to 12€</span></p>
                                    <p>Result: {!isCpmAndResultHidden && <span>{cpmObj.result}</span>}</p>
                                </div>
                            </div>

                            {fieldsForChangeCampaign._id && (
                                <SubmitButton ref={saveChangesCampaignRef} onSubmit={updateCampaignOnServer}/>
                            )}
                        </div>
                    )}
                </div>

                {!data?.isCopy && (
                    <div className="admin-close-campaign-button">
                        <StandardButton style={{margin: '0 auto 50px auto'}} isRed={true} text="COMPLETE"
                                        onClick={() => setIsClosePromoModalOpen(true)}/>
                    </div>
                )}

                {data ? (
                    <div>
                        <div ref={containerRef}>
                            {isDeleteInfluencerModalOpen && (
                                <ModalWindow isOpen={isDeleteInfluencerModalOpen}
                                             setClose={() => setIsDeleteInfluencerModalOpen(false)}>
                                    <div style={{padding: '25px 40px', maxWidth: '1000px', fontFamily: 'Geometria'}}>
                                        <h1 style={{textAlign: 'center'}}>You want to
                                            delete {fieldsForChangeInfluencer.instagramUsername} from this
                                            campaign?</h1>
                                        <div style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            padding: '20px 60px 0 60px'
                                        }}>
                                            <StandardButton onClick={() => {
                                                removeInfluencer(fieldsForChangeInfluencer.selectedInfluencersAccountId);
                                                setIsDeleteInfluencerModalOpen(false)
                                            }} text='Yes' isBlue={true}/>
                                            <StandardButton onClick={() => setIsDeleteInfluencerModalOpen(false)}
                                                            text='No' isBlue={true}/>
                                        </div>
                                    </div>
                                </ModalWindow>
                            )}
                            {(fieldsForChangeVideo.selectedInstagramUsername || fieldsForChangeInfluencer.instagramUsername) && (
                                <SubmitButton ref={saveChangesRef} onSubmit={updateCampaignVideoOnServer}/>
                            )}

                            {isVideoLinkEdit && fieldsForChangeVideo.currentCampaignContentItemId && (
                                <ModalWindow isOpen={isVideoLinkEdit} setClose={() => setIsVideoLinkEdit(false)}>
                                    <div style={{padding: '20px', maxWidth: '1000px', fontFamily: 'Geometria',}}>
                                        <h1 style={{textAlign: 'center'}}>VIDEO LINK</h1>
                                        <p style={{
                                            textAlign: "center",
                                            marginTop: 10,
                                            width: '100%',
                                        }}>{fieldsForChangeVideo?.videoLink}</p>

                                        <h1 style={{textAlign: 'center', marginTop: '30px'}}>SELECT FROM EXISTING</h1>
                                        <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                                            {data?.videos.map((video, index) => (
                                                <button
                                                    onClick={() => {
                                                        setFieldsForChangeVideo((prev) => ({
                                                            ...prev,
                                                            newCampaignContentItemId: video._id,
                                                        }));
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        paddingLeft: 5.1,
                                                        paddingRight: 5,
                                                        borderRadius: "10px",
                                                        border: "1.5px solid black",
                                                        boxSizing: 'border-box',
                                                        cursor: 'pointer',
                                                        padding: '0 5px 0 5px',
                                                        width: 70,
                                                        height: 28,
                                                        marginLeft: 8,
                                                    }}>
                                                    <img src={videoImg} alt="video"/>
                                                    <span style={{
                                                        fontWeight: 700,
                                                        fontFamily: 'Geometria',
                                                        marginBottom: 2
                                                    }}>#{index + 1}</span>
                                                    <img src={linkImg} alt="link"/>
                                                </button>
                                            ))}
                                        </div>

                                        {fieldsForChangeVideo.newCampaignContentItemId && (
                                            <div style={{
                                                marginTop: 30,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: "center",
                                                justifyContent: 'center',
                                                gap: '15px'
                                            }}>
                                                <h1>SELECTED VIDEO</h1>
                                                <p style={{
                                                    wordWrap: 'break-word',
                                                    flexWrap: 'wrap'
                                                }}>{data?.videos.find((video) => video._id === fieldsForChangeVideo.newCampaignContentItemId).videoLink}</p>
                                            </div>
                                        )}

                                        <StandardButton style={{
                                            margin: '0 auto',
                                            marginTop: '20px',
                                            width: '100%',
                                        }} text='Save' isBlue={true} onClick={() => {
                                            setIsVideoLinkEdit(false);
                                            updateCampaignVideoOnServer();
                                        }}/>
                                    </div>
                                </ModalWindow>
                            )}

                            {isNewInfluencerVideoLinkEdit && (
                                <ModalWindow isOpen={isNewInfluencerVideoLinkEdit}
                                             setClose={() => setIsNewInfluencerVideoLinkEdit(false)}>
                                    <div style={{padding: '20px', maxWidth: '1000px', fontFamily: 'Geometria',}}>
                                        <h1 style={{textAlign: 'center'}}>VIDEO LINK</h1>
                                        <TextInput
                                            style={{marginTop: '10px'}}
                                            value={inputForNewVideoLink}
                                            setValue={setInputForNewVideoLink}
                                        />
                                        <h1 style={{textAlign: 'center', marginTop: 30}}>SELECT FROM EXISTING</h1>
                                        <div style={{marginTop: 10, display: 'flex', justifyContent: 'center'}}>
                                            {data?.videos.map((video, index) => (
                                                <button
                                                    onClick={() => {
                                                        setNewInfluencerVideoLink(video._id);
                                                    }}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        paddingLeft: 5.1,
                                                        paddingRight: 5,
                                                        borderRadius: "10px",
                                                        border: "1.5px solid black",
                                                        boxSizing: 'border-box',
                                                        cursor: 'pointer',
                                                        padding: '0 5px 0 5px',
                                                        width: 70,
                                                        height: 28,
                                                        marginLeft: 8,
                                                    }}>
                                                    <img src={videoImg} alt="video"/>
                                                    <span style={{
                                                        fontWeight: 700,
                                                        fontFamily: 'Geometria',
                                                        marginBottom: 2
                                                    }}>#{index + 1}</span>
                                                    <img src={linkImg} alt="link"/>
                                                </button>
                                            ))}
                                        </div>

                                        {newInfluencerVideoLink && (
                                            <div style={{
                                                marginTop: 30,
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: "center",
                                                justifyContent: 'center',
                                                gap: '15px'
                                            }}>
                                                <h1>SELECTED VIDEO</h1>
                                                <p style={{
                                                    wordWrap: 'break-word',
                                                    flexWrap: 'wrap'
                                                }}>{data?.videos.find((video) => video._id === newInfluencerVideoLink).videoLink}</p>
                                            </div>
                                        )}

                                        <StandardButton style={{margin: '0 auto', marginTop: 20, width: '100%'}}
                                                        text='Save' isBlue={true}
                                                        onClick={() => {
                                                            setSelectedNewInfluencer({
                                                                ...selectedNewInfluencer,
                                                                selectedCampaignContentItem: newInfluencerVideoLink,
                                                            })
                                                            setIsNewInfluencerVideoLinkEdit(false);
                                                            setNewInfluencerVideoLink('');
                                                        }}
                                        />
                                    </div>
                                </ModalWindow>
                            )}

                            {isPostLinkEdit && fieldsForChangeInfluencer.postLink && (
                                <ModalWindow isOpen={isPostLinkEdit} setClose={() => setIsPostLinkEdit(false)}>
                                    <div style={{padding: '20px', maxWidth: '1000px', fontFamily: 'Geometria',}}>
                                        <h1 style={{textAlign: 'center', marginTop: '30px'}}>NEW POST LINK</h1>
                                        <TextInput style={{marginTop: '10px'}} name='postLink'
                                                   value={fieldsForChangeInfluencer.postLink} setValue={(newValue) =>
                                            setFieldsForChangeInfluencer((prev) => ({
                                                ...prev,
                                                postLink: newValue,
                                            }))
                                        }/>
                                        <StandardButton style={{
                                            margin: '0 auto',
                                            marginTop: '20px',
                                            width: '100%',
                                        }} text='Save' isBlue={true} onClick={() => {
                                            setIsPostLinkEdit(false);
                                            updateCampaignVideoOnServer();
                                        }}/>
                                    </div>
                                </ModalWindow>
                            )}

                            {isScreenshotEdit && fieldsForChangeInfluencer.screenshot && (
                                <ModalWindow isOpen={isScreenshotEdit} setClose={() => setIsScreenshotEdit(false)}>
                                    <div style={{padding: '20px', maxWidth: '1000px', fontFamily: 'Geometria',}}>
                                        <h1 style={{textAlign: 'center', marginTop: '30px'}}>NEW SCREENSHOT LINK</h1>
                                        <TextInput style={{marginTop: '10px'}} name='screenshot'
                                                   value={fieldsForChangeInfluencer.screenshot} setValue={(newValue) =>
                                            setFieldsForChangeInfluencer((prev) => ({
                                                ...prev,
                                                screenshot: newValue,
                                            }))
                                        }/>
                                        <StandardButton style={{
                                            margin: '0 auto',
                                            marginTop: '20px',
                                            width: '100%',
                                        }} text='Save' isBlue={true} onClick={() => {
                                            setIsScreenshotEdit(false);
                                            updateCampaignVideoOnServer();
                                        }}/>
                                    </div>
                                </ModalWindow>
                            )}

                            {isAddInfluencerModaolOpen && (
                                <ModalWindow isOpen={isAddInfluencerModaolOpen}
                                             setClose={() => setIsAddInfluencerModaolOpen(false)}>
                                    <div style={{padding: '20px', maxWidth: '1000px', fontFamily: 'Geometria',}}>
                                        {data?.socialMedia === 'multipromo' && <div>
                                            <h1 style={{textAlign: 'center', marginTop: '30px'}}>SELECT SOCIAL
                                                MEDIA</h1>
                                            <div style={{width: '550px', margin: '20px auto 0'}}>
                                                <SelectSocialMediaHorizontalList
                                                    selectedSocialMedia={selectedSocialMedia}
                                                    selectSocialMedia={setSelectedSocialMedia}/>
                                            </div>
                                        </div>}
                                        <h1 style={{textAlign: 'center', marginTop: '30px'}}>INPUT SOCIAL NETWORK
                                            USERNAME</h1>
                                        <TextInput style={{marginTop: 15}} value={newInfluenceInput}
                                                   setValue={setNewInfluenceInput}/>
                                        {isErrorAfterAddingInfluencer && (
                                            <p style={{
                                                color: 'red',
                                                textAlign: 'center',
                                                fontSize: 18,
                                                fontWeight: 600,
                                                marginTop: 10
                                            }}>Influencer not found</p>
                                        )}
                                        <StandardButton style={{margin: '0 auto', marginTop: 20, width: '100%'}}
                                                        text='Add New Influencer' isBlue={true}
                                                        onClick={addNewInfluencerToTempList}/>
                                    </div>
                                </ModalWindow>
                            )}

                            <div className="admin-table-container" style={{width: '95%', margin: '0 auto 50px auto'}}>
                                <table className="admin-table">
                                    <thead className="admin-table-header">
                                    <tr>
                                        <th>Networks</th>
                                        <th>Total Followers</th>
                                        <th>Date Post</th>
                                        <th>Video</th>
                                        <th>Post Description</th>
                                        <th>Story Tag</th>
                                        <th>Story Link</th>
                                        <th>Special Requests</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                        <th>Post Link</th>
                                        <th>Insights Screenshots</th>
                                        <th>Impressions</th>
                                        <th>Likes</th>
                                    </tr>
                                    </thead>
                                    <tbody className="admin-table-body">
                                    {data?.selectInfluencers.map((influencer, index) => (
                                        <tr onClick={() => selectInfluencer(influencer)}>
                                            {/*networks*/}
                                            <td className="admin-table-body-td" style={{width: 210, paddingLeft: 3}}>
                                                <div style={{
                                                    display: data?.socialMedia === 'multipromo' && 'flex',
                                                    gap: data?.socialMedia === 'multipromo' && 5
                                                }}>
                                                    {data?.socialMedia === 'multipromo' && <img style={{width: 15}}
                                                                                                src={getSocialMediaIconForPickAndChoose(influencer.socialMedia)}
                                                                                                alt=''/>}
                                                    <p style={{
                                                        fontFamily: 'Geometria',
                                                        fontSize: '16px',
                                                        fontWeight: 700,
                                                        textAlign: 'left',
                                                    }}>
                                                        {influencer?.instagramUsername}
                                                    </p>
                                                </div>
                                            </td>
                                            {/*total followers*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 90, margin: 0, padding: 0}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'center',
                                                }}>
                                                    {influencer?.followersNumber}
                                                </p>
                                            </td>
                                            {/*date post*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 90, margin: 0, padding: 0}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'center',
                                                }}>
                                                    {influencer?.dateRequest}
                                                </p>
                                            </td>
                                            {/*video*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 120, margin: 0, padding: 0}}>
                                                <div style={{display: 'flex', padding: '3px 0 3px 0'}}>
                                                    <button
                                                        onClick={() => {
                                                            window.open(influencer?.videoDetails?.videoLink, '_blank');
                                                        }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            paddingLeft: 5.1,
                                                            paddingRight: 5,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            padding: '0 5px 0 5px',
                                                            width: 70,
                                                            height: 28,
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={videoImg} alt="video"/>
                                                        <span style={{
                                                            fontWeight: 700,
                                                            fontFamily: 'Geometria',
                                                            marginBottom: 2
                                                        }}>#{getNumOfInfluencersVideo(influencer)}</span>
                                                        <img src={linkImg} alt="link"/>
                                                    </button>
                                                    <button
                                                        onClick={() => setIsVideoLinkEdit(true)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            paddingLeft: 4,
                                                            paddingRight: 3,
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={editImg} alt="watch"/>
                                                    </button>
                                                </div>
                                            </td>
                                            {/*post description*/}
                                            <td className="admin-table-body-td" style={{width: 250}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '13px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.postDescription : influencer.videoDetails?.postDescription}
                                                    name='postDescription'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*story tag*/}
                                            <td className="admin-table-body-td" style={{width: 150}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.storyTag : influencer.videoDetails?.storyTag}
                                                    name='storyTag'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*story link*/}
                                            <td className="admin-table-body-td" style={{width: 120}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.swipeUpLink : influencer.videoDetails?.swipeUpLink}
                                                    name='swipeUpLink'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*special requests*/}
                                            <td className="admin-table-body-td" style={{width: 250}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '13px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        padding: 0,
                                                        margin: 0,
                                                    }}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.specialWishes : influencer.videoDetails?.specialWishes}
                                                    name='specialWishes'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*status*/}
                                            <td className="admin-table-body-td" style={{width: 100}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'left',
                                                }}>
                                                    {getStatusForInfluencer(influencer) !== 'Closed' ? (
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'space-between',
                                                            alignItems: 'center'
                                                        }}>
                                                            {getStatusForInfluencer(influencer)}
                                                            <img
                                                                onClick={() => closeCampaignForInfluencer(influencer._id, params.campaignId)}
                                                                src={checkImg} alt='closePromo' style={{
                                                                filter: 'invert(1)',
                                                                cursor: 'pointer',
                                                                width: 15,
                                                                height: 15,
                                                                paddingRight: 5
                                                            }}/>
                                                        </div>
                                                    ) : (
                                                        getStatusForInfluencer(influencer)
                                                    )}
                                                </p>
                                            </td>
                                            {/*actions*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 120, margin: 0, padding: 0}}>
                                                <div style={{display: 'flex'}}>
                                                    <button
                                                        onClick={() => {
                                                            setIsDeleteInfluencerModalOpen(true)
                                                        }}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            paddingLeft: 1,
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={deleteImg} alt="deleteInfluencer"/>
                                                    </button>
                                                    <button
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={addImg} alt="addInfluencer"/>
                                                    </button>
                                                    <button
                                                        onClick={() => sendMailToInfluencer(influencer.instagramUsername, influencer.influencerId, influencer.videoDetails.videoLink, influencer.videoDetails.postDescription, influencer.dateRequest, influencer.videoDetails.storyTag, influencer.videoDetails.swipeUpLink)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={emailImg} alt="email"/>
                                                    </button>
                                                </div>
                                            </td>
                                            {/*post link*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 105, margin: 0, padding: 0}}>
                                                {influencer.postLink ? (
                                                    <div style={{display: 'flex', padding: '5px 0 5px 0'}}>
                                                        <button
                                                            onClick={() => {
                                                                window.open(influencer.postLink, '_blank');
                                                            }}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                paddingLeft: 6,
                                                                paddingRight: 5,
                                                                borderRadius: "10px",
                                                                border: "1.5px solid black",
                                                                boxSizing: 'border-box',
                                                                cursor: 'pointer',
                                                                padding: '0 5px 0 5px',
                                                                width: 52,
                                                                height: 28,
                                                                marginLeft: 8,
                                                            }}>
                                                            <img src={instagramIcon} alt="insta"/>
                                                            <img src={linkImg} alt="link"/>
                                                        </button>
                                                        <button
                                                            onClick={() => setIsPostLinkEdit(true)}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                width: 28,
                                                                height: 28,
                                                                borderRadius: "10px",
                                                                paddingLeft: 4,
                                                                paddingRight: 3,
                                                                border: "1.5px solid black",
                                                                boxSizing: 'border-box',
                                                                cursor: 'pointer',
                                                                marginLeft: 8,
                                                            }}>
                                                            <img src={editImg} alt="watch"/>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <input
                                                        style={{
                                                            fontFamily: "Geometria",
                                                            fontSize: '16px',
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            width: '100%',
                                                        }}
                                                        value={fieldsForChangeInfluencer.selectedInfluencersAccountId === influencer._id ? fieldsForChangeInfluencer.postLink : influencer.postLink}
                                                        name='postLink'
                                                        onChange={updateInfluencerFieldsInput}
                                                    />
                                                )}
                                            </td>
                                            {/*insights screenshots*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 105, margin: 0, padding: 0}}>
                                                {influencer.screenshot ? (
                                                    <div style={{display: 'flex', padding: '5px 0 5px 0'}}>
                                                        <button
                                                            onClick={() => {
                                                                window.open(influencer.screenshot, '_blank');
                                                            }}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                paddingLeft: 6,
                                                                paddingRight: 5,
                                                                borderRadius: "10px",
                                                                border: "1.5px solid black",
                                                                boxSizing: 'border-box',
                                                                cursor: 'pointer',
                                                                padding: '0 5px 0 5px',
                                                                width: 52,
                                                                height: 28,
                                                                marginLeft: 8,
                                                            }}>
                                                            <img src={imageImg} alt="image"/>
                                                            <img src={linkImg} alt="link"/>
                                                        </button>
                                                        <button
                                                            onClick={() => setIsScreenshotEdit(true)}
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                width: 28,
                                                                height: 28,
                                                                borderRadius: "10px",
                                                                paddingLeft: 4,
                                                                paddingRight: 3,
                                                                border: "1.5px solid black",
                                                                boxSizing: 'border-box',
                                                                cursor: 'pointer',
                                                                marginLeft: 8,
                                                            }}>
                                                            <img src={editImg} alt="edit"/>
                                                        </button>
                                                    </div>
                                                ) : (
                                                    <input
                                                        style={{
                                                            fontFamily: "Geometria",
                                                            fontSize: '16px',
                                                            fontWeight: 400,
                                                            textAlign: "left",
                                                            width: '100%',
                                                        }}
                                                        value={fieldsForChangeInfluencer.selectedInfluencersAccountId === influencer._id ? fieldsForChangeInfluencer.screenshot : influencer.screenshot}
                                                        name='screenshot'
                                                        onChange={updateInfluencerFieldsInput}
                                                    />
                                                )}
                                            </td>
                                            {/*impressions*/}
                                            <td className="admin-table-body-td" style={{width: 85}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChangeInfluencer.selectedInfluencersAccountId === influencer._id ? fieldsForChangeInfluencer.impressions : influencer.impressions}
                                                    name='impressions'
                                                    onChange={updateInfluencerFieldsInput}
                                                />
                                            </td>
                                            {/*likes*/}
                                            <td className="admin-table-body-td" style={{width: 85}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={fieldsForChangeInfluencer.selectedInfluencersAccountId === influencer._id ? fieldsForChangeInfluencer.like : influencer.like}
                                                    name='like'
                                                    onChange={updateInfluencerFieldsInput}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    {newInfluencersList.map((influencer, index) => (
                                        <tr onClick={() => selectNewInfluencer(influencer)}>
                                            {/*networks*/}
                                            <td className="admin-table-body-td" style={{width: 210}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 700,
                                                    textAlign: 'left',
                                                }}>
                                                    {influencer?.instagramUsername}
                                                </p>
                                            </td>
                                            {/*total followers*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 90, margin: 0, padding: 0}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'center',
                                                }}>
                                                    {influencer?.followersCount}
                                                </p>
                                            </td>
                                            {/*date post*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 90, margin: 0, padding: 0}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                    }}
                                                    value={selectedNewInfluencer._id === influencer._id ? selectedNewInfluencer.dateRequest : influencer.dateRequest}
                                                    name='dateRequest'
                                                    onChange={updateNewInfluencerInput}
                                                />
                                            </td>
                                            {/*video*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 120, margin: 0, padding: 0}}>
                                                <div style={{display: 'flex', padding: '3px 0 3px 0', margin: 0}}>
                                                    {influencer?.selectedVideo !== '' ? (
                                                        <div style={{
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            width: '100%'
                                                        }}>
                                                            <button
                                                                onClick={() => {
                                                                    window.open(influencer?.videoDetails?.videoLink, '_blank');
                                                                }}
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    paddingLeft: 7,
                                                                    paddingRight: 6,
                                                                    borderRadius: "10px",
                                                                    border: "1.5px solid black",
                                                                    boxSizing: 'border-box',
                                                                    cursor: 'pointer',
                                                                    padding: '0 5px 0 5px',
                                                                    width: 54,
                                                                    height: 28,
                                                                }}>
                                                                <img src={videoImg} alt="video"/>
                                                                <img src={linkImg} alt="link"/>
                                                            </button>
                                                            <button
                                                                onClick={() => setIsVideoLinkEdit(true)}
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    width: 28,
                                                                    height: 28,
                                                                    borderRadius: "10px",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 3,
                                                                    border: "1.5px solid black",
                                                                    boxSizing: 'border-box',
                                                                    cursor: 'pointer',
                                                                    marginLeft: 8,
                                                                }}>
                                                                <img src={editImg} alt="watch"/>
                                                            </button>
                                                        </div>
                                                    ) : (
                                                        <div style={{
                                                            alignItems: 'center',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            width: '100%',
                                                        }}>
                                                            <button
                                                                onClick={() => setIsNewInfluencerVideoLinkEdit(true)}
                                                                style={{
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'space-between',
                                                                    width: 28,
                                                                    height: 28,
                                                                    borderRadius: "10px",
                                                                    paddingLeft: 4,
                                                                    paddingRight: 3,
                                                                    border: "1.5px solid black",
                                                                    boxSizing: 'border-box',
                                                                    cursor: 'pointer',
                                                                }}>
                                                                <img src={editImg} alt="watch"/>
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </td>
                                            {/*post description*/}
                                            <td className="admin-table-body-td" style={{width: 250}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'left',
                                                    cursor: 'default',
                                                }}>
                                                    {influencer?.videoDetails?.postDescription}
                                                </p>
                                            </td>
                                            {/*story tag*/}
                                            <td className="admin-table-body-td" style={{width: 150}}>
                                                <input
                                                    readOnly={true}
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        cursor: 'default',
                                                    }}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.storyTag : influencer.videoDetails?.storyTag}
                                                    name='storyTag'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*story link*/}
                                            <td className="admin-table-body-td" style={{width: 120}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        cursor: 'default',
                                                    }}
                                                    readOnly={true}
                                                    value={fieldsForChangeVideo.selectedInfluencersAccountId === influencer._id ? fieldsForChangeVideo.swipeUpLink : influencer.videoDetails?.swipeUpLink}
                                                    name='swipeUpLink'
                                                    onChange={updateCampaignVideoFieldsInput}
                                                />
                                            </td>
                                            {/*special requests*/}
                                            <td className="admin-table-body-td" style={{width: 250}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'left',
                                                    cursor: 'default',
                                                }}>
                                                    {influencer?.videoDetails?.specialWishes}
                                                </p>
                                            </td>
                                            {/*status*/}
                                            <td className="admin-table-body-td" style={{width: 100}}>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 400,
                                                    textAlign: 'left',
                                                }}>
                                                    {getStatusForInfluencer(influencer)}
                                                </p>
                                            </td>
                                            {/*actions*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 120, margin: 0, padding: 0}}>
                                                <div style={{display: 'flex'}}>
                                                    <button
                                                        onClick={() => removeNewInfluencerFromList(influencer._id)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            paddingLeft: 1,
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={deleteImg} alt="deleteInfluencer"/>
                                                    </button>
                                                    <button
                                                        onClick={() => addInfleuncerToCampaign(influencer._id)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={addSecondImg} alt="addInfluencer"/>
                                                    </button>
                                                    <button
                                                        onClick={() => sendMailToInfluencer(influencer.instagramUsername, influencer.influencerId, influencer.videoDetails.videoLink, influencer.videoDetails.postDescription, influencer.dateRequest, influencer.videoDetails.storyTag, influencer.videoDetails.swipeUpLink)}
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            width: 28,
                                                            height: 28,
                                                            borderRadius: "10px",
                                                            border: "1.5px solid black",
                                                            boxSizing: 'border-box',
                                                            cursor: 'pointer',
                                                            marginLeft: 8,
                                                        }}>
                                                        <img src={emailImg} alt="email"/>
                                                    </button>
                                                </div>
                                            </td>
                                            {/*post link*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 105, margin: 0, padding: 0}}>
                                            </td>
                                            {/*insights screenshots*/}
                                            <td className="admin-table-body-td"
                                                style={{width: 105, margin: 0, padding: 0}}>
                                            </td>
                                            {/*impressions*/}
                                            <td className="admin-table-body-td" style={{width: 85}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        cursor: 'default',
                                                    }}
                                                    readOnly={true}
                                                    value=""
                                                    name='impressions'
                                                    onChange={updateInfluencerFieldsInput}
                                                />
                                            </td>
                                            {/*likes*/}
                                            <td className="admin-table-body-td" style={{width: 85}}>
                                                <input
                                                    style={{
                                                        fontFamily: "Geometria",
                                                        fontSize: '15px',
                                                        fontWeight: 400,
                                                        textAlign: "left",
                                                        width: '100%',
                                                        cursor: 'default',
                                                    }}
                                                    readOnly={true}
                                                    value=""
                                                    name='like'
                                                    onChange={updateInfluencerFieldsInput}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="admin-table-body-td"
                                            style={{background: "#c0f4d4", margin: 0, padding: 0}}>
                                            <div style={{
                                                padding: '5px 0 5px 0',
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 3,
                                                cursor: 'pointer',
                                            }} onClick={() => setIsAddInfluencerModaolOpen(true)}>
                                                <button
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        width: 25,
                                                        height: 25,
                                                        borderRadius: "10px",
                                                        paddingLeft: 3,
                                                        paddingRight: 2,
                                                        border: "1.5px solid black",
                                                        boxSizing: 'border-box',
                                                        cursor: 'pointer',
                                                        marginLeft: 8,
                                                    }}>
                                                    <img src={addSecondImg} alt="addSecond"/>
                                                </button>
                                                <p style={{
                                                    fontFamily: 'Geometria',
                                                    fontSize: '16px',
                                                    fontWeight: 700,
                                                    textAlign: 'left',
                                                }}>Add Influencer</p>
                                            </div>
                                        </td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                        <td className="admin-table-body-td"></td>
                                    </tr>
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ff7c0c", border: 'none', padding: '8px 0 8px 3px'}}>
                                            {!isPriceHidden ? (
                                                <div style={{
                                                    padding: '8px 0 8px 10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    gap: 3,
                                                    cursor: 'pointer',
                                                    fontFamily: 'Geometria',
                                                    fontSize: 18,
                                                    fontWeight: 800,
                                                    textAlign: 'left'
                                                }}>
                                                    <p>PRICE: </p>
                                                    <p>{fieldsForChangeCampaign.campaignName ? fieldsForChangeCampaign.amount : data?.amount}</p>
                                                </div>
                                            ) : <div style={{height: 40}}></div>}
                                        </td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none', padding: 0}}>
                                            <p style={{
                                                fontFamily: 'Geometria',
                                                fontSize: 16,
                                                fontWeight: 700,
                                                textAlign: 'center'
                                            }}>{data?.totalFollowers}</p>
                                        </td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}></td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#f0ac84", border: 'none'}}>
                                            <p style={{
                                                fontFamily: 'Geometria',
                                                fontSize: 16,
                                                fontWeight: 700,
                                                textAlign: 'center'
                                            }}>{totalImpressions()}</p>
                                        </td>
                                        <td className="admin-table-body-td"
                                            style={{background: "#ffbc84", border: 'none'}}>
                                            <p style={{
                                                fontFamily: 'Geometria',
                                                fontSize: 16,
                                                fontWeight: 700,
                                                textAlign: 'center'
                                            }}>{totalLikes()}</p>
                                        </td>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>

                        {isClosePromoModalOpen && (
                            <ModalWindow isOpen={isClosePromoModalOpen}
                                         setClose={() => setIsClosePromoModalOpen(false)}>
                                <div style={{padding: "80px 80px 0 80px"}}>
                                    <p style={{
                                        fontFamily: "Geometria",
                                        fontSize: 24,
                                        fontWeight: 800,
                                        textAlign: "center",
                                    }}>
                                        <b>Is the campaign completed?</b>
                                    </p>
                                    <div style={{
                                        display: 'flex',
                                        gap: 30,
                                        justifyContent: 'center',
                                        marginTop: 40,
                                        marginBottom: 64
                                    }}>
                                        <StandardButton text="Yes, it's completed"
                                                        style={{background: "red", width: 300}}
                                                        onClick={() => closeCampaign()}/>
                                        <StandardButton text="No, it's not completed" style={{width: 300}} isBlue={true}
                                                        onClick={() => {
                                                            setIsClosePromoModalOpen(false)
                                                        }}/>
                                    </div>
                                </div>
                            </ModalWindow>
                        )}
                    </div>
                ) : (
                    <Loading/>
                )}
            </div>
            <NotificationContainer/>
        </section>
    );
};

export default AdminCampaignManagement;



