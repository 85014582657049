import React, {useEffect, useState} from "react";
import TitleSection from "../../../../TitleSection";
import {useLocation, useParams} from "react-router-dom";
import axios from "axios";
import UseVerify from "../../../../../hooks/useVerify";
import {
    extractNumber,
} from "../../../../../utils/validations";
import {useNavigate} from "react-router-dom";
import arrow from "../../../../../images/icons/arrow.svg";
import roundedArrow from "../../../../../images/rounded-arrow.png";
import shareIcon from "../../../../../images/icons/Share.svg";
import StandardButton from "../../../../form/StandardButton";
import {setClearFormCreatePromo, setCurrentWindow} from "../../../../../redux/slice/create-promo";
import ModalWindow from "../../../../ModalWindow";
import acceptIcon from "../../../../../images/icons/accept.svg";
import {decodeAndDecrypt, encryptAndEncode} from "../../../../../utils/crypt";
import Loading from "../../../../form/PageLoading/pageLoading";
import {ReportCampaignDesktopTable} from "./ReportCampaignDesktopTable";
import {NotificationManager} from "react-notifications";
import {ReportCampaignMobileTable} from "./ReportCampaignMobileTable";
import {useQuery} from "react-query";
import {Error} from "../../../../form/Error/Error";
import {CsvCampaignConverter} from "../../../../../utils/converters/CsvCampaignConverter";
import {captureAndDownloadPDF} from "../../../../../utils/converters/PdfCampaignConverter";

const fetchData = async (params) => {
    const {dataFetch} = await UseVerify();

    const result = await axios(
        `${process.env.REACT_APP_SERVER}/promos/client-campaign/one?campaignId=${encryptAndEncode(decodeAndDecrypt(params.id, process.env.REACT_APP_URL_PAGES_SECRET_KEY), process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}&clientId=${encryptAndEncode(dataFetch._id, process.env.REACT_APP_API_ENCRYPTION_SECRET_KEY)}`
    );

    if (result.data.code === 200) {
        return result.data.campaign;
    }

    throw new Error('Error loading campaign information');
};

const ReportCampaigns = () => {
    const params = useParams();
    const [valueForSendingInvoiceWithoutPONumber, setValueForSendingInvoiceWithoutPONumber] = useState("");
    const [valueForSendingInvoiceWithPONumber, setValueForSendingInvoiceWithPONumber] = useState("");
    const [valueForPONumber, setValueForPONumber] = useState("");
    const [poNumber, setPoNumber] = useState("");
    const [isPopup, setIsPopup] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isPdfDownloading, setIsPdfDownloading] = useState(false);

    const location = useLocation();
    const navigation = useNavigate();

    const [cpmObj, setCpmObj] = useState({
        cpm: 0,
        avgCpm: '',
        result: '',
    });
    const navigate = useNavigate();

    const {data, isError, isLoading, isFetching} = useQuery(
        ["client-ongoing-promo", params],
        () => fetchData(params),
        {
            retry: 2,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchInterval: false,
            refetchIntervalInBackground: false,
            refetchIntervalInForeground: false,
            staleTime: 0,
            cacheTime: 0,
            onError: (error) => {
                NotificationManager.error('Error loading campaign', 'Error', 3000);
            }
        }
    );

    useEffect(() => {
        if (data && data?.selectInfluencers.length > 0) {
            editCPMO();
        }
    }, [data])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const editCPMO = () => {
        if (data?.selectInfluencers?.find(influencer => influencer.impressions > 0) && data?.isCpmAndResultHidden === false) {
            const price = data?.amount || 0;

            if (price === 0) {
                return;
            }

            const cpm = price / totalImpressions() * 1000;
            let avgCpm;

            if (cpm < 3) {
                avgCpm = '0 to 3€';
            } else if (cpm < 5) {
                avgCpm = '3€ to 5€';
            } else if (cpm < 9) {
                avgCpm = '5€ to 9€';
            } else if (cpm < 12) {
                avgCpm = '9€ to 12€';
            } else {
                avgCpm = '>12€';
            }

            let result;
            if (avgCpm === '0 to 3€') {
                result = 'Excellent';
            } else if (avgCpm === '3€ to 5€') {
                result = 'Highly Above Average';
            } else if (avgCpm === '5€ to 9€') {
                result = 'Above Average';
            } else if (avgCpm === '9€ to 12€') {
                result = 'Average';
            } else if (avgCpm === '>12€') {
                result = 'Below Average';
            } else {
                result = 'Poor';
            }

            setCpmObj({
                cpm,
                avgCpm,
                result,
            });
        }
    }

    const handleBackButton = () => {
        if (location.pathname.includes("past-promos")) {
            navigate("/account/client/past-promos");
        } else {
            navigate("/account/client/ongoing-promos");
        }
    };

    const totalImpressions = () => {
        return data.selectInfluencers.reduce((prev, current) => {
            return prev + extractNumber(current.impressions);
        }, 0);
    };

    const totalLikes = () => {
        return data.selectInfluencers.reduce((prev, current) => {
            return prev + extractNumber(current.like);
        }, 0);
    };

    const createTransferForSendingInvoiceWithoutPO = async () => {
        const promoId = data?._id;
        try {
            const {dataFetch} = await UseVerify();
            if (!promoId) {
                console.error('promoId is not set');
                return;
            }
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/promos/update-estimate?isPoNeed=false`,
                null,
                {
                    params: {
                        promoId: promoId,
                    }
                }
            );
            if (result.data.status === 200) {
                await axios.post(
                    `${process.env.REACT_APP_SERVER}/payment/create-order-tranfer`,
                    {
                        nameProduct: `Offers ${data.selectPrice.variant}`,
                        userId: dataFetch._id,
                        amount: data.selectPrice.price,
                        emailForSendingInvoice: valueForSendingInvoiceWithoutPONumber,
                    }
                );
                location.reload();
            }
        } catch (err) {
            console.log(err);
        }
    }

    const createTransferForSendingInvoiceWithPO = async () => {
        try {
            const promoId = data?._id;
            const {dataFetch} = await UseVerify();
            if (!promoId) {
                console.error('promoId is not set');
                return;
            }
            const result = await axios.put(
                `${process.env.REACT_APP_SERVER}/promos/update-estimate?isPoNeed=true`,
                null,
                {
                    params: {
                        promoId: promoId,
                    }
                }
            );
            if (result.data.status === 200) {
                await axios.post(
                    `${process.env.REACT_APP_SERVER}/payment/create-order-tranfer`,
                    {
                        nameProduct: `Offers ${data.selectPrice.variant}`,
                        userId: dataFetch._id,
                        amount: data.selectPrice.price,
                        emailForSendingInvoice: valueForSendingInvoiceWithPONumber,
                        poNumber: valueForPONumber,
                    }
                );
                location.reload()
            }
        } catch (err) {
            console.log(err);
        }
    }

    const handleDownloadCsv = () => {
        CsvCampaignConverter({
            ...data,
            totalFollowers: data?.totalFollowers || 0,
            accountsCount: data?.selectInfluencers.length || 0,
            totalLikes: totalLikes() || 0,
            totalImpressions: totalImpressions() || 0,
            cpm: `${cpmObj.cpm.toFixed(2)}€`,
            cpmResult: cpmObj.result
        })
    };

    const handleDownloadPdf = async () => {
        setIsPdfDownloading(true);
        await captureAndDownloadPDF(data.campaignName);
        setIsPdfDownloading(false);
    };

    const handleCopyLink = () => {
        if (!data?.shareLink) {
            console.error('No share link to copy');
            NotificationManager.error('Error copying link');
            return;
        }
        navigator.clipboard.writeText(data?.shareLink)
        NotificationManager.success('Copied to clipboard')
    };

    return (
        <section className="report">
            <div style={{position: "relative"}}>
                <div className="back-btn-title"
                     style={{width: windowWidth <= 850 ? '95%' : '90%'}}>
                    <button onClick={handleBackButton}>
                        <img src={arrow} alt={'back'}/>
                    </button>

                    <div id='campaign-report-title'>
                        <TitleSection title="CAMPAIGN" span="REPORT"/>
                    </div>

                    {!isError && !isLoading && !isFetching && data && window.innerWidth > 1000 && (
                        <div className="features-btns">
                            <div id='csv-download' onClick={handleDownloadCsv}>
                                <img src={roundedArrow} alt={'csv-download'}/>
                                <p>CSV FILE</p>
                            </div>
                            {!isPdfDownloading &&
                                <div id='csv-download' onClick={handleDownloadPdf}>
                                    <img src={roundedArrow} alt={'pdf-download'}/>
                                    <p>PDF FILE</p>
                                </div>
                            }
                            <div id='share-link' onClick={handleCopyLink}>
                                <img src={shareIcon} alt={'share-link'}/>
                                <p>SHARE</p>
                            </div>
                        </div>
                    )}
                </div>

                {!isError && !isLoading && !isFetching && data && (
                    <div>
                        <div className="report-campaign-name" id='campaign-report-campaign-name'>
                            <p>{data?.campaignName}</p>
                        </div>
                    </div>
                )}

                {!isError && !isLoading && !isFetching && data && window.innerWidth <= 1000 && (
                    <div className="features-btns" style={{position: "relative", margin: '30px auto 0'}}>
                        <div id='csv-download' onClick={handleDownloadCsv}>
                            <img src={roundedArrow} alt={'csv-download'}/>
                            <p>CSV FILE</p>
                        </div>
                        {/*<div id='csv-download' onClick={handleDownloadCsv}>*/}
                        {/*    <img src={roundedArrow} alt={'pdf-download'}/>*/}
                        {/*    <p>PDF FILE</p>*/}
                        {/*</div>*/}
                        <div id='share-link' onClick={handleCopyLink}>
                            <img src={shareIcon} alt={'share-link'}/>
                            <p>SHARE</p>
                        </div>
                    </div>
                )}

                {!isError && !isLoading && !isFetching && data && (
                    <div className="account-client-campaign-strategy-details" id='campaign-report-details'
                         style={{marginTop: '40px', marginBottom: 30}}>
                        <div className="account-client-campaign-strategy-details-first">
                            <p>Date Submitted: <span>{new Date(data?.createdAt).toLocaleDateString('en-GB')}</span></p>
                            <p>Price: <span>{data?.isPriceHidden ? '' : data?.amount || data.campaignPrice}</span></p>
                            <p>Posts & Stories: <span>{data?.selectInfluencers.length}</span></p>
                        </div>
                        <div className="account-client-campaign-strategy-details-second">
                            <p>Combined Followers: <span>{data?.totalFollowers}</span></p>
                            <p>Impressions: <span>{totalImpressions()}</span></p>
                            <p>Likes: <span>{totalLikes()}</span></p>
                        </div>
                        <div className="account-client-campaign-strategy-details-third">
                            <p>CPM: <span>{cpmObj.cpm.toFixed(2)}€</span></p>
                            <p>Average Instagram CPM: <span>5€ to 12€</span></p>
                            <p>Result: <span>{cpmObj.result}</span></p>
                        </div>
                    </div>
                )}

                {isError && <Error errorMessage="Something went wrong, please try again later" marginTop={30}/>}
                {(isLoading || isFetching || isPdfDownloading) && <Loading marginTop={30}/>}
                {!isError && !isLoading && !isFetching && !isPdfDownloading && data && (
                    windowWidth > 1150 ? (
                        <ReportCampaignDesktopTable
                            data={data}
                            totalPrice={data.campaignPrice}
                            totalLikes={totalLikes()}
                            totalImpressions={totalImpressions()}
                            totalFollowers={data.totalFollowers}
                        />
                    ) : (
                        <ReportCampaignMobileTable
                            data={data}
                            totalPrice={data.campaignPrice}
                            totalFollowers={data.totalFollowers}
                            totalImpressions={totalImpressions()}
                            totalLikes={totalLikes()}
                        />
                    )
                )}
            </div>

            <ModalWindow isOpen={isPopup} setClose={setIsPopup}>
                <div className="signup-client-modal">
                    <img className="signup-client-modal-icon" alt='' src={acceptIcon}/>

                    <h2 className="signup-client-modal-title">Congratulations!</h2>

                    <p className="signup-client-modal-second">
                        You can now check the status of your Promotion request in the{" "}
                        <button
                            className="signup-client-modal-second"
                            style={{
                                color: "#3330E4",
                                textDecorationLine: "underline",
                                cursor: "pointer",
                            }}
                            onClick={() => {
                                setClearFormCreatePromo();
                                setCurrentWindow(0);
                                navigation("/account/client/ongoing-promos")
                            }}
                        >
                            "Ongoing Promo"
                        </button>
                    </p>

                    <StandardButton
                        text="Ok"
                        style={{
                            padding: "8px 80px",
                            marginTop: "30px",
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}
                        onClick={() => {
                            setIsPopup(false);
                        }}
                    />
                </div>
            </ModalWindow>
        </section>
    );
};

export default ReportCampaigns;