import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import TitleSection from "../../../../TitleSection";
import {extractNumber} from "../../../../../utils/validations";
import Loading from "../../../../form/PageLoading/pageLoading";
import {NotificationContainer, NotificationManager} from "react-notifications";
import {PromoShareDesktopTable} from "./PromoShareDesktopTable";
import {PromoShareMobileTable} from "./PromoShareMobileTable";
// import {CsvCampaignConverter} from "../../../../../utils/converters/CsvCampaignConverter";
// import StandardButton from "../../../../form/StandardButton";

const PromoShare = () => {
    const params = useParams();
    const [dataCampaign, setDataCampaign] = useState();
    const [cpmObj, setCpmObj] = useState({
        cpm: 0,
        avgCpm: '',
        result: '',
    });
    const [isPriceHidden, setIsPriceHidden] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (dataCampaign && dataCampaign?.selectInfluencers.length > 0) {
            editCPMO();
        }
    }, [dataCampaign])

    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const getData = async () => {
        try {
            const result = await axios.get(
                `${process.env.REACT_APP_SERVER}/promos/share-link/${params.promoId}/${params.socialMedia}`
            );
            if (result.data.code === 200) {
                setDataCampaign(result.data.campaign);
                setIsPriceHidden(result.data.campaign.isPriceHidden);
                return;
            }

            throw new Error('Error loading campaign information');
        } catch (err) {
            NotificationManager.error('Error loading campaign information', 'Error', 5000);
            console.log(err);
        }
    };

    const editCPMO = () => {
        if (dataCampaign?.selectInfluencers?.find(influencer => influencer.impressions > 0) && dataCampaign?.isCpmAndResultHidden === false) {
            const price = dataCampaign.amount ? dataCampaign.amount : dataCampaign.selectPrice.price;
            const cpm = price / totalImpressions() * 1000;
            let avgCpm;

            if (cpm < 3) {
                avgCpm = '0 to 3€';
            } else if (cpm < 5) {
                avgCpm = '3€ to 5€';
            } else if (cpm < 9) {
                avgCpm = '5€ to 9€';
            } else if (cpm < 12) {
                avgCpm = '9€ to 12€';
            } else {
                avgCpm = '>12€';
            }

            let result;
            if (avgCpm === '0 to 3€') {
                result = 'Excellent';
            } else if (avgCpm === '3€ to 5€') {
                result = 'Highly Above Average';
            } else if (avgCpm === '5€ to 9€') {
                result = 'Above Average';
            } else if (avgCpm === '9€ to 12€') {
                result = 'Average';
            } else if (avgCpm === '> 12€') {
                result = 'Below Average';
            } else {
                result = 'Poor';
            }

            setCpmObj({
                cpm,
                avgCpm,
                result,
            });
        }
    }

    const totalFollowers = () => {
        let total = 0;

        dataCampaign?.selectInfluencers.forEach((influencer) => {
            total += influencer.followersNumber;
        });

        if (dataCampaign?.isCopy) total = dataCampaign?.totalFollowers;

        return total;
    };

    const totalImpressions = () => {
        if (!dataCampaign?.selectInfluencers) return 0;
        const total = dataCampaign.selectInfluencers.reduce((prev, current) => {
            return prev + extractNumber(current.impressions);
        }, 0);
        return total;
    };

    const totalLikes = () => {
        return dataCampaign?.selectInfluencers.reduce((prev, current) => {
            const currentLikes = parseInt(current.like || "0");
            return prev + (isNaN(currentLikes) ? 0 : currentLikes);
        }, 0);
    };

    if (!dataCampaign) return (
        <div>
            <Loading/>
            <NotificationContainer/>
        </div>
    );
    return (
        <section className="report">
            <div style={{position: "relative"}}>
                <div className="account-client-title" style={{marginTop: windowWidth <= 850 ? 20 : 0}}>
                    <TitleSection title="Campaign" span="Report"/>
                    <p style={{
                        fontSize: 24,
                        fontWeight: 700,
                        fontFamily: "Geometria",
                        textAlign: "center",
                    }}>{dataCampaign?.campaignName}</p>
                </div>

                <div className="report-details">
                    <div className="report-details-first">
                        <p>Date Submitted: <span>{new Date(dataCampaign?.createdAt).toLocaleDateString('en-GB')}</span>
                        </p>
                        <p>Price:
                            {!isPriceHidden && <span style={{marginLeft: 5}}>{dataCampaign?.amount}{dataCampaign?.currency}</span>}
                        </p>
                        <p>Posts & Stories: <span>{dataCampaign?.selectInfluencers.length}</span></p>
                    </div>
                    <div className="report-details-second">
                        <p>Combined Followers: <span>{totalFollowers()}</span></p>
                        <p>Impressions: <span>{totalImpressions()}</span></p>
                        <p>Likes: <span>{totalLikes()}</span></p>
                    </div>
                    <div className="report-details-third">
                        <p>CPM: {!dataCampaign?.isCpmAndResultHidden && <span>{cpmObj.cpm.toFixed(2)}€</span>}</p>
                        <p>Average Instagram CPM: <span>5€ to 12€</span></p>
                        <p>Result: {!dataCampaign?.isCpmAndResultHidden && <span>{cpmObj.result}</span>}</p>
                    </div>
                </div>

                {windowWidth > 1150 ? <PromoShareDesktopTable data={dataCampaign}
                                                              totalFollowers={totalFollowers()}
                                                              totalImpressions={totalImpressions()}
                                                              totalLikes={totalLikes()}
                                                              isPriceHidden={isPriceHidden}
                                                              totalPrice={dataCampaign.amount}/> :
                    <PromoShareMobileTable data={dataCampaign}
                                           totalFollowers={totalFollowers()}
                                           totalImpressions={totalImpressions()}
                                           totalLikes={totalLikes()}
                                           isPriceHidden={isPriceHidden}
                                           totalPrice={dataCampaign.amount}/>}
            </div>
            <NotificationContainer/>
        </section>
    );
};

export default PromoShare;