import React, {useEffect, useState} from 'react'
import "./addInfluencerToCampaignModal.css";
import ModalWindow from "../../ModalWindow";
import TitleSection from "../../TitleSection";
import SearchBar from "../SearchBar/SearchBar";
import StandardButton from "../StandardButton";
import {getSocialMediaIconForPickAndChoose} from "../../../utils/typeOfSocialAccounts";

export const AddInfluencerToCampaignModal = ({isOpen, setClose, influencers, selectInfluencer}) => {
    const [searchResult, setSearchResult] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (searchResult) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [searchResult]);
    
    const searchByUsername = (data, searchInput) => {
        return data.filter(influencer =>
            influencer.instagramUsername && influencer.instagramUsername.toLowerCase().includes(searchInput.toLowerCase())
        );
    };
    
    return (
        <ModalWindow isOpen={isOpen} setClose={setClose}>
            <div className="add-influencer-modal">
                <TitleSection title="find" span="influencer"/>
                
                <div className="search-container">
                    <SearchBar data={influencers} className="large" setSearchResult={setSearchResult} searchFunction={searchByUsername} typeOfSearch="influencers"/>
                </div>

                {searchResult && (
                    <div className={`result-block ${isVisible ? "visible" : ""}`}>
                        <div id='acc-info'>
                            <div className="logo-username">
                                <div id='sm-logo'>
                                    <img id="sm" src={getSocialMediaIconForPickAndChoose(searchResult.socialMedia)} alt=""/>
                                    <img id="logo" src={searchResult.logo} alt=""/>
                                </div>
                                <p>{searchResult.instagramUsername}</p>
                            </div>
                            <div className="price-followers">
                                <p>Price: <span>{searchResult.publicPrice}€</span></p>
                                <p>Followers: <span>{searchResult.followersNumber}</span></p>
                            </div>
                        </div>
                        <div className="add-influencer-button">
                            <StandardButton text="Add Influencer" isBlue={true} onClick={() => {
                                selectInfluencer(searchResult);
                                setClose();
                            }}/>
                        </div>
                    </div>
                )}
            </div>
        </ModalWindow>
    )
}
